import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)


import registrationInfo from './registrationInfo';
import childAttachment from './childAttachment';
import materialInformation from './materialInformation/index';
import parentInfomation from './parentInfomation/index';
import infantInformation from './infantInformation/index';
import regChidGarden from './regChidGarden/index';
import familyMember from './familyMember';
import zsDateRange from './zsDateRange';
import children from './children';

export default new Vuex.Store({
  modules: {
    registrationInfo,
    childAttachment,
    materialInformation,
    parentInfomation,
    infantInformation,
    regChidGarden,
    familyMember,
    zsDateRange,
    children
  }
})
