
export const ApiUrl = process.env.VUE_APP_ApiUrl;
export const BaseUrl = process.env.VUE_APP_BaseUrl;


export const config = {
    // 随 打包模式
    ApiServicesUrl : ApiUrl + '/api/services/app/',
    ApiUrl : ApiUrl
};

export const ApiServicesUrl = config.ApiServicesUrl;


export const LoginUrl=`https://zwdtuser.sh.gov.cn/uc/naturalUser/jump.do?redirect_uri=${ApiUrl}/helper/GetPCLoginToken`;