import axiosHttpService from '@/core/utils/axios';
import qs from 'qs';

import {
    GetRefChildGardenPageDto,
    IGetRefChildGardenPageInputDto,
    SaveRefChildGardenDto,
    IGetRegGardenByChildIdInputDto,
    IGetRegGardenByChildIdDto,
    FullChildRegInfoDto
} from './dto/refChildGardenDto';

export class ZsRefChildGardenService {
    getRefChildGardenPage(input: IGetRefChildGardenPageInputDto) {
        return axiosHttpService.get<GetRefChildGardenPageDto>({
            url: 'ZSRefChildGarden/GetRefChildGardenPage',
            params: input
        })
    }
    getRegGardenByChildId(input: IGetRegGardenByChildIdInputDto) {
        return axiosHttpService.get<Array<IGetRegGardenByChildIdDto>>({
            url: 'ZSRefChildGarden/GetRegGardenByChildId',
            params: input
        })
    }
    getFullChildRegInfo(input: SaveRefChildGardenDto) {
        return axiosHttpService.get<FullChildRegInfoDto>({
            url: 'ZSRefChildGarden/GetFullChildRegInfo?' + qs.stringify(input, { arrayFormat: 'repeat' })
        })
    }
    getDetailChildRegInfo(childId: number) {
        return axiosHttpService.get<FullChildRegInfoDto>({
            url: 'ZSRefChildGarden/GetDetailChildRegInfo',
            params: { childId: childId }
        })
    }
    saveRefChildGardenPage(input: SaveRefChildGardenDto) {
        return axiosHttpService.post<Array<IGetRegGardenByChildIdDto>>({
            url: 'ZSRefChildGarden/SaveRefChildGardenPage',
            data: input
        })
        // return axiosHttpService.httpServiceNoCatch<Array<IGetRegGardenByChildIdDto>>({
        //     url:'ZSRefChildGarden/SaveRefChildGardenPage',
        //     data:input
        // })
    }
    deleteRefChildGardens(childId: number) {
        return axiosHttpService.post({
            url: 'ZSRefChildGarden/DeleteRefChildGardens',
            params: { childId: childId }
        });
    }

}
export default new ZsRefChildGardenService();