import { RouteConfig } from 'vue-router';

const routes: RouteConfig = {
    path: '/notThisCity',
    name: 'notThisCity',
    component: () => import('./index.vue'),
    children:[
        {
            path: 'parentInfomation',
            name: 'ParentInfomation',
            component: () => import('./01-parentInfomation/index.vue'),
        },
        {
            path: 'childInformation',
            name: 'ChildInformation',
            component: () => import('./02-childInformation/index.vue'),
        },
        {
            path: 'familyMemberInformation',
            name: 'FamilyMemberInformation',
            component: () => import('./03-familyMemberInformation/index.vue'),
        },
        {
            path: 'familyMemberInformation/addFamilyMembers',
            name: 'AddFamilyMemberInformation',
            component: () => import('./03-familyMemberInformation/pages/actionFamilyMembers/index.vue'),
        },
        {
            path: 'familyMemberInformation/updateFamilyMembers',
            name: 'UpdateFamilyMembers',
            component: () => import('./03-familyMemberInformation/pages/actionFamilyMembers/index.vue'),
        },
        {
            path: 'parentScore',
            name: 'ParentScore',
            component: () => import('./04-parentScore/index.vue'),
        },
        {
            path: 'materialInformation',
            name: 'MaterialInformation',
            component: () => import('./05-materialInformation/index.vue'),
        },
        {
            path: 'regChildGarden',
            name: 'RegChildGarden',
            component: () => import('./06-regChildGarden/index.vue'),
        },
        {
            path: 'confirmInformation',
            name: 'ConfirmInformation',
            component: () => import('./07-confirmInformation/index.vue'),
        },
        // {
        //     path: 'seeInformation/:childId',
        //     name: 'xiaoBanSignUpSeeInformation',
        //     component: () => import('./09-seeInformation/index.vue'),
        // },
    ]
};

export default routes;