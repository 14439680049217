import { SaveChildBaseInfo } from '@/app/apiServices/zsChildBaseInfo/public-api';
import { StoreOptions } from 'vuex';

const store: StoreOptions<any> = {
    state() {
        return {
            childBaseInfo: new SaveChildBaseInfo()
        }
    },
    mutations: {
        SET_ChildBaseInfo(state, data) {
            state.childBaseInfo = data;
            localStorage.setItem('childBaseInfo', JSON.stringify(data))
        }
    },
    actions: {
        ACTION_ChildBaseInfo(context, data) {
            context.commit('SET_ChildBaseInfo', data)
        }
    },
    getters: {
        GET_ChildBaseInfo(state) {
            const childBaseInfo = localStorage.getItem('childBaseInfo');
            if (childBaseInfo) {
                return JSON.parse(childBaseInfo);
            }
            return state.childBaseInfo;
        }
    }
};

export default store;