import { SaveParentHoldPageDto } from '@/app/apiServices/parentHoldRegister/public-api';
import { StoreOptions } from 'vuex';

const store: StoreOptions<any> = {
    state() {
        return {
            // parentInfomation: {
            //     isHoldResidencePermit: 0,    //父母一方持证情况    1：上海居住证   2：上海居住登记凭证   3：海外人才居住证   4：境外人员合法居住证明
            //     isApplyScore: 0,         //是否申请积分   1：是
            //     isStandardScore: 0,      //是否达到分值   1:达到分值
            // }
            parentInfomation: new SaveParentHoldPageDto()
        }
    },
    mutations: {
        SET_ParentInfomation(state, data) {
            state.parentInfomation = data;
            localStorage.setItem('parentInfomation', JSON.stringify(data))
        }
    },
    actions: {
        ACTION_ParentInfomation(context, data) {
            context.commit('SET_ParentInfomation', data)
        }
    },
    getters: {
        GET_ParentInfomation(state) {
            const parentInfomation = localStorage.getItem('parentInfomation');
            if (parentInfomation) {
                return JSON.parse(parentInfomation);
            }
            return state.parentInfomation;
        }
    }
};

export default store;