import axiosHttpService from '@/core/utils/axios';
import { BaseResultDto } from '../baseDto/resultDto';
import { IGetZSPointTableGradePage, SaveZSPointTableGradePage } from './public-api';

export class ZSPointTableGradeService {
    //获取打分表页面数据
    getZSChildBaseInfoPage(childId: string) {
        return axiosHttpService.get<IGetZSPointTableGradePage>({
            url: 'ZSPointTableGrade/GetZSPointTableGradePage',
            params: { childId }
        })
    }
    //保存打分表数据
    saveZSChildBaseInfoPage(input: SaveZSPointTableGradePage) {
        return axiosHttpService.post<BaseResultDto>({
            url: 'ZSPointTableGrade/SaveZSPointTableGradePage',
            data: input
        })
    }
}

export default new ZSPointTableGradeService();