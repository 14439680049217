import { ISelectDto } from "@/app/apiServices/baseDto/selectDto";
import { IVerifyResult } from "../../baseDto/resultDto";


export interface IGetParentHoldPageDataInput {
    // gardenId: string | null,
    gradeCode: string,
    cardNumber: string,
    cardType?: string
}

export interface IParentHoldPage {
    id: number,
    gB_ZJLX?: string | null,
    gB_ZJLX_CODE: string,
    idCardNo: string,
    isHoldResidencePermit?: number | null,
    holderName: string,
    childRelation: string | null,
    childRelationDec: string,
    holderCard: string,
    isApplyScore?: number | null,
    isApplyScoreDec: string,
    isStandardScore?: number | null,
    isStandardScoreDec: string,
    isTemporaryPermit?: number | null,
    temporaryPermitDate?: Date,
    temporaryPermitEndDate?: Date,
    temporaryPermitAdr: string,
    isHoldJobregister?: number | null,
    jobRegisterCard: string,
    addtime?: Date | null,
    liveCard: string,
    checkIsHoldResidencePermit?: number | null,
    checkHolderName: string,
    checkChildRelation: string,
    checkHolderCard: string,
    checkIsApplyScore?: number | null,
    checkIsStandardScore?: number | null,
    checkIsTemporaryPermit?: number | null,
    checkTemporaryPermitDate?: Date | null,
    checkTemporaryPermitEndDate?: Date | null,
    checkTemporaryPermitAdr: string,
    checkIsHoldJobregister?: number | null,
    checkJobRegisterCard: string,
    checkLiveCard: string,
    checkAcount: string,
    checkTime?: Date | null,
    residencePermitType?: number | null,
    hasSixMonthSocialSecurity?: number | null,
    parentsAllLocal: number,
    // gardenId: number,
    gradeCode: string,
}

export class SaveParentHoldPageDto implements IParentHoldPage {
    id = 0;
    gB_ZJLX = "";
    gB_ZJLX_CODE = "";
    idCardNo = "";
    isHoldResidencePermit = null;
    holderName = "";
    childRelation = null;
    childRelationDec = "";
    holderCard = "";
    isApplyScore = null;
    isApplyScoreDec = "";
    isStandardScore = null;
    isStandardScoreDec = "";
    isTemporaryPermit = null;
    temporaryPermitDate = new Date;
    temporaryPermitEndDate = new Date;
    temporaryPermitAdr = "";
    isHoldJobregister = null;
    jobRegisterCard = "";
    addtime = null;
    liveCard = "";
    checkIsHoldResidencePermit = null;
    checkHolderName = "";
    checkChildRelation = "";
    checkHolderCard = "";
    checkIsApplyScore = null;
    checkIsStandardScore = null;
    checkIsTemporaryPermit = null;
    checkTemporaryPermitDate = null;
    checkTemporaryPermitEndDate = null;
    checkTemporaryPermitAdr = "";
    checkIsHoldJobregister = null;
    checkJobRegisterCard = "";
    checkLiveCard = "";
    checkAcount = "";
    checkTime = null;
    residencePermitType = null;
    hasSixMonthSocialSecurity = null;
    parentsAllLocal = 0;
    // gardenId = 0;
    gradeCode = '';
}

export interface IParentHoldRegisterInfo extends IParentHoldPage {
    holdCardSelect: Array<ISelectDto> | [],
    parentRelationSelect: Array<ISelectDto> | [],
    verifyResult: IVerifyResult,
    holdResidencePermitDec: string,
    childRelationDec: string,
    isApplyScoreDec: string,
    isStandardScoreDec: string
}

export class ParentHoldRegisterInfoDto {
    id = 0;
    gB_ZJLX = "";
    gB_ZJLX_CODE = "";
    idCardNo = "";
    isHoldResidencePermit = null;
    holderName = "";
    childRelation = null;
    holderCard = "";
    isApplyScore = null;
    isStandardScore = null;
    isTemporaryPermit = null;
    temporaryPermitDate = new Date;
    temporaryPermitEndDate = new Date;
    temporaryPermitAdr = "";
    isHoldJobregister = null;
    jobRegisterCard = "";
    addtime = null;
    liveCard = "";
    checkIsHoldResidencePermit = null;
    checkHolderName = "";
    checkChildRelation = "";
    checkHolderCard = "";
    checkIsApplyScore = null;
    checkIsStandardScore = null;
    checkIsTemporaryPermit = null;
    checkTemporaryPermitDate = null;
    checkTemporaryPermitEndDate = null;
    checkTemporaryPermitAdr = "";
    checkIsHoldJobregister = null;
    checkJobRegisterCard = "";
    checkLiveCard = "";
    checkAcount = "";
    checkTime = null;
    residencePermitType = null;
    hasSixMonthSocialSecurity = null;
    parentsAllLocal = 0;
    gardenId = 0;
    holdCardSelect = [];
    parentRelationSelect = [];
    verifyResult = {
        message: '',
        success: true
    };
    holdResidencePermitDec = '';
    childRelationDec = '';
    isApplyScoreDec = '';
    isStandardScoreDec = '';
}

