import { RegistrationInfoDto } from '@/app/apiServices/baseDto/storeDto';
import Vue from 'vue';
import { StoreOptions } from 'vuex';

const store: StoreOptions<any> = {
    state() {
        return {
            // registrationInfo: {
            //     childId: 0,
            //     cardTypeName: '',//证件类型名称
            //     cardType: '',//证件类型Code
            //     cardNumber: '',  //证件号
            //     childName: '',  //姓名
            //     regNumber: '',   //登记编号
            //     birthday: '',    //生日
            //     childType: 0,   //户籍类型
            //     gradeCode: '',    //年级code
            // },
            registrationInfo: new RegistrationInfoDto()
        }
    },
    mutations: {
        SET_RegistrationInfo(state, data) {
            state.registrationInfo = data;
            Object.keys(state.registrationInfo).forEach((key: any) => {
                Vue.set(state.registrationInfo, key, data[key])
            });
            localStorage.setItem('registrationInfo', JSON.stringify(data));
        }
    },
    actions: {
        ACTION_RegistrationInfo(context, data) {
            context.commit('SET_RegistrationInfo', data)
        }
    },
    getters: {
        GET_RegistrationInfo(state) {
            const registrationInfo = localStorage.getItem('registrationInfo');
            if (registrationInfo) {
                return JSON.parse(registrationInfo);
            }
            return state.registrationInfo;
        }
    }
};

export default store;