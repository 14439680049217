import { ChildAttachmentGroupDtos } from "../../childAttachment/public-api";
import { ParentHoldRegisterInfoDto } from "../../parentHoldRegister/public-api";
import { ChildBaseInfo } from "../../zsChildBaseInfo/public-api";
import { ChildGuarderInfo } from "../../zsChildGuarderInfo/public-api";
import { ChildOhterInfoDto } from "../../zsChildOhterInfo/public-api";
import { PointTableGardeDto } from "../../zsPointTableGrade/public-api";

export class GetRefChildGardenPageDto {
    // curGarden = new Garden();
    // canChooseGardenList = [];
    // changeEnterGardenType = false;
    // changeEnterGardenTypeReminder = "";
    // changeDistrict = "";
    // changeDistrictReminder = "";
    // changeStreet = "";
    // changeStreetReminder = "";
    gardenGroupDtos: Array<ZSRefChildGardenCategoryDto> = [];
    orderTimeList = [];
    showOrderTime = false;
    // zsRefChildGardenDtos: Array<ZSRefChildGardenDtos> = []
}
export interface IGetRefChildGardenPageInputDto {
    childId: string;
    enterGardenType: number;
    gradeCode: string;
}

// export interface ISaveRefChildGardenPageDto {
//     childId: number;
//     gardenIds: Array<number>;
//     enterGardenType: number;
//     gradeCode: string;
//     orderTime: string,
// }

export class SaveRefChildGardenDto {
    childId = 0;
    gardenIds: any[] = [];
    enterGardenType = 0;
    gradeCode = "";
    orderTime = "";
    isAdjust = 0;  //是否接受统筹
}
export interface IGetRegGardenByChildIdInputDto {
    childId: string;
    gradeCode: string;
}
export interface IGetRegGardenByChildIdDto {
    childID: number;
    enterGardenType: number;
    gardenID: number;
    gardenName: string;
    gradeCode: string;
    id: number;
    isAdjust: number;
    level: number;
    opTime: Date;
    ordertime: Date;
    ownershipCode: string;
    reason: string;
    reasonCode: string;
    regAccount: string;
    regTime: Date;
    status: number;
    year: number;
}

export interface IGarden {
    name: string;
    shortCode: string;
    code: string;
    area: string;
    level: number;
    type: string;
    typeCode: string;
    street: string;
    streetCode: string;
    telphone: string;
    address: string;
    ownership: string;
    ownershipCode: string;
    language: string;
    languageCode: string;
    remark: string;
    isParcel: number;
    parentID: number;
    isDelete: number;
    isRegisterSite: number;
    isDisplay: number;
    isMbZS: number;
    benshiSort: number;
    kindergarten: string;
    iStbzs: boolean;
    iSxbzs: boolean;
    fees: string;
    admissionNotes: string;
    enrollScope: string;
    id: number;
}

export class Garden implements IGarden {
    name = '';
    shortCode = '';
    code = '';
    area = '';
    level = 0;
    type = '';
    typeCode = '';
    street = '';
    streetCode = '';
    telphone = '';
    address = '';
    ownership = '';
    ownershipCode = '';
    language = '';
    languageCode = '';
    remark = '';
    isParcel = 0;
    parentID = 0;
    isDelete = 0;
    isRegisterSite = 0;
    isDisplay = 0;
    isMbZS = 0;
    benshiSort = 0;
    kindergarten = '';
    iStbzs = false;
    iSxbzs = false;
    fees = '';
    admissionNotes = '';
    enrollScope = '';
    id = 0;
}

export interface ZSRefChildGardenCategoryDto {
    category: string,
    gardenList: Array<IGarden>,
    isMultiple: boolean,
    sortIndex: number
}


export interface ZSRefChildGardenDtos {
    address: string;
    childID: number | null;
    enterGardenType: number;
    gardenID: number;
    gardenName: string;
    gradeCode: string;
    hasSupplied: boolean | null;
    id: number;
    isAdjust: number;
    level: number;
    opTime: Date | null;
    ordertime: string;
    ownershipCode: string;
    parentID: number;
    preConfirmGid: number | null;
    reason: string | null;
    reasonCode: string | null;
    regAccount: string | null;
    regTime: Date | null;
    status: number;
    street: string;
    streetCode: string;
    year: number;
}

export class RefChildGardenDto {
    id = 0;
    childID = 0;
    gardenID = 0;
    gardenName = '';
    ownershipCode = '';
    street = '';
    streetCode = '';
    address = '';
    level = '';
    year = 0;
    opTime = null;
    status = 0;
    regAccount = '';
    regTime = null;
    enterGardenType = 0;
    gradeCode = '';
    reason = '';
    reasonCode = '';
    ordertime = '';
    isAdjust = 0;
    preConfirmGid = 0;
    hasSupplied = false;
}

//报名完整信息
export class FullChildRegInfoDto {
    cbReason = '';
    enterGardenType = 0;
    enterGardenTypeDec = "";
    grade = "";
    gradeCode = "";
    optime = "";
    showPointTable = false;
    targetOutputDto = new PointTableGardeDto();   //打分表信息
    zsChildBaseInfoDto = new ChildBaseInfo();   //幼儿基本信息
    parentHoldRegisterOutputDto = new ParentHoldRegisterInfoDto();   //父母持证信息
    zsChildGuarderInfoDtos: Array<ChildGuarderInfo> = [];   //监护人信息
    zsChildOhterInfoDto = new ChildOhterInfoDto();   //幼儿其他信息
    zsRefChildGardenDtos: Array<RefChildGardenDto> = [];   //报名园所信息
    childAttachmentInfoDto = new ChildAttachmentGroupDtos();   //佐证信息

    // 验证消息
    verifyResult = {
        message: '',
        success: true
    };
}