import axiosHttpService from '@/core/utils/axios';
import {
    ISaveChildBaseInfo,
    ZSChildBaseInfoPageInput,
    ZSChildBaseInfoPageSaveDto,
    getZSChildBaseInfoPageDto,
    SaveChildBaseInfoResult
} from './dtos/childBaseInfo';

/**
 * 招生幼儿基本信息
 * */
export class ZSChildBaseInfoService {
    /*
    * 保存幼儿基本信息页面数据
    * */
    saveZSChildBaseInfoPage(input: ZSChildBaseInfoPageSaveDto) {
        return axiosHttpService.post<SaveChildBaseInfoResult>({
            url: 'ZSChildBaseInfo/SaveZSChildBaseInfoPage',
            data: input
        });
    }

    getById(id: string) {
        return axiosHttpService.get<ISaveChildBaseInfo>({
            url: 'ZSChildBaseInfo/GetById',
            params: { id }
        });
    }

    getZSChildBaseInfoPage(input: ZSChildBaseInfoPageInput) {
        // 默认身份证
        if (!input.cardType) {
            input.cardType = 'ZJLX_sfz';
        }
        return axiosHttpService.get<getZSChildBaseInfoPageDto>({
            url: 'ZSChildBaseInfo/GetZSChildBaseInfoPage',
            params: input
        })
    }

    deleteZSChildBaseInfo(childId: number) {
        return axiosHttpService.post({
            url: 'ZSChildBaseInfo/DeleteZSChildBaseInfo',
            params: { childId: childId }
        });
    }
}

export default new ZSChildBaseInfoService();