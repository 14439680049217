<template>
  <div id="layout-top-div">
    <div id="layout-header-div">
      <div id="layout-nav-left-div">
        <van-icon
            @click="backToPreviousPage()"
            name="arrow-left"
            size="18"
            color="#bcbec0" />
      </div>
      <div id="layout-nav-title-div">{{title}}</div>
    </div>
    <div id="layout-body-div"><slot></slot></div>
  </div>
</template>

<script>
import "./layoutTop.css";
export default {
  name:'layout-top',
  props:{
    title:{
      type:String
    }
  },
  methods:{
    backToPreviousPage(){
      window.history.back();
    },
    setStyle(){
      const layoutTopDiv = window.document.getElementById('layout-top-div');
      layoutTopDiv.style.width = window.document.body.clientWidth + 'px';
      layoutTopDiv.style.height = window.document.body.clientHeight + 'px';
    }
  },
  // mounted() {
  //   window.onresize = () =>{
  //     this.setStyle();
  //   };
  //   this.setStyle();
  // }
}
</script>