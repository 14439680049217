import { CurrentUserDto } from '@/app/apiServices/baseDto/storeDto';
import { StoreOptions } from 'vuex';
const store: StoreOptions<any> = {
    state() {
        return {
            // currentUser: {
            //     token: '',
            //     encryptId: '',
            //     data: {}
            // }
            currentUser: new CurrentUserDto()
        }
    },
    getters: {
        GET_CurrentUser(state) {
            let currentUser = '';
            if (process.env.VUE_APP_ENV != "DEV") {
                currentUser = sessionStorage.getItem('currentUser') || "";
            } else {
                const data = {
                    token: '',
                    encryptId: '111',
                    data: {}
                };
                currentUser = JSON.stringify(data);   //本地开发测试用
            }
            if (currentUser) {

                return JSON.parse(currentUser);
            }
            return state.activeGarten;
        }
    }
};

export default store;