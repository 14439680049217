import axiosHttpService from '@/core/utils/axios';
import { ChildAttachmentGroupDtos, getAttachmentPageInput, IChildAttachment, SaveAttachmentPageDto } from './dtos/childAttachment'


export class ChildAttachmentService {
    // getAttachmentPage(childId: string) {
    //     return axiosHttpService.get<Array<IChildAttachment>>({
    //         url: "ChildAttachment/GetAttachmentPage",
    //         params: { childId }
    //     })
    // }
    getAttachmentPage(input: getAttachmentPageInput) {
        return axiosHttpService.get<ChildAttachmentGroupDtos>({
            url: "ChildAttachment/GetAttachmentPage",
            params: input
        })
    }
    // saveAttachmentPage(data: Array<IChildAttachment>) {
    //     return axiosHttpService.post<any>({
    //         url: "ChildAttachment/SaveAttachmentPage",
    //         data
    //     })
    // }
    saveAttachmentPage(data: SaveAttachmentPageDto) {
        return axiosHttpService.post<any>({
            url: "ChildAttachment/SaveAttachmentPage",
            data
        })
    }
    getById(id: string) {
        return axiosHttpService.get<IChildAttachment>({
            url: "ChildAttachment/GetById",
            params: { id }
        })
    }

    delete(id: number) {
        return axiosHttpService.post<number>({
            url: "ChildAttachment/BaseDelete",
            params: { id }
        })
    }
}

export default new ChildAttachmentService();