<template>
  <!--  linear-gradient(to right, #E4C995, #B9916A)-->
  <!-- <Button
      class="ssb-primary-button"
      :long="long" :size="size">
    <slot></slot>
  </Button> -->
  <el-button type="primary" class="ssb-primary-button" :size="size">
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: "ssb-primary-button",
  props: {
    size: {
      type: String,
      default: ''
    },
  }
}
</script>

<style scoped>
.ssb-primary-button {
  /* background: linear-gradient(to right, #E4C995, #B9916A); */
  color: #fff !important;
  font-size: 1.48rem;
  font-weight: bold;
  border-color: #CBB486;
  background: #CBB486;
  padding: 12px 28px !important;
}
.ssb-primary-button:hover{
  border-color: #CBB486;
  background: #CBB486;
}
.ssb-primary-button:focus{
  border-color: #CBB486;
  background: #CBB486;
}
.ssb-primary-button:active{
  border-color: #CBB486;
  background: #CBB486;
}
</style>