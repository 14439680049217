import axiosHttpService from '@/core/utils/axios';
import { BaseResultDto } from '../baseDto/resultDto';
import { IGetParentHoldPageDataInput, IParentHoldRegisterInfo, SaveParentHoldPageDto } from "./dtos/ParentHoldRegister";

export class ParentHoldRegisterService {
    getParentHoldPage(input: IGetParentHoldPageDataInput) {
        // 默认身份证
        if (!input.cardType) {
            input.cardType = 'ZJLX_sfz';
        }
        return axiosHttpService.get<IParentHoldRegisterInfo>({
            url: 'ParentHoldRegister/GetParentHoldPage',
            params: input
        })
    }
    saveParentHoldPage(input: SaveParentHoldPageDto) {
        return axiosHttpService.post<BaseResultDto>({
            url: 'ParentHoldRegister/SaveParentHoldPage',
            data: input
        })
    }
}

export default new ParentHoldRegisterService();