<template>
    <el-button class="ssb-default-button" :size="size">
      <slot></slot>
    </el-button>
  </template>
  
  <script>
  export default {
    name: "ssb-default-button",
    props: {
      size: {
        type: String,
        default: ''
      },
    }
  }
  </script>
  
  <style scoped>
  .ssb-default-button {
    font-size: 1.48rem;
    font-weight: bold;
    border-color: #CBB486;
    padding: 12px 28px !important;
  }
  .ssb-default-button:hover{
    border-color: #CBB486;
    background: #fff;
    color: rgba(0, 0, 0, 0.5);
  }
  .ssb-default-button:focus{
    border-color: #CBB486;
    background: #fff;
    color: rgba(0, 0, 0, 0.5);
  }
  .ssb-default-button:active{
    border-color: #CBB486;
    background: #fff;
    color: rgba(0, 0, 0, 0.5);
  }
  </style>