import axiosHttpService from '@/core/utils/axios';
import { BaseResultDto } from '../baseDto/resultDto';

import { IChildGuarderInfo } from './dtos/childGuarderInfo'

export class ZsChildGuarderInfoService{
    /*
    * 新增 和 保存
    * */
    saveGuarderInfoPage(input:IChildGuarderInfo){
        return axiosHttpService.post<BaseResultDto>({
            url:'ZSChildGuarderInfo/SaveGuarderInfoPage',
            data:input
        })
    }

    /*
    * 获得监护人列表
    * */
    getGuarderList(childId:number){
        return axiosHttpService.get<Array<IChildGuarderInfo>>({
            url:'ZSChildGuarderInfo/GetGuarderList',
            params:{
                childId
            }
        })
    }

    // 设置为监护人
    setDefaultGuarder(id:number){
        return axiosHttpService.post({
            url:'ZSChildGuarderInfo/SetDefaultGuarder',
            params:{id}
        })
    }

    /**
     * 获得监护人
     * */
    getGuarder(id:string){
        return axiosHttpService.get<IChildGuarderInfo>({
            url:'ZSChildGuarderInfo/GetGuarder',
            params:{id}
        })
    }

    delete(id:number){
        return axiosHttpService.post({
            url:'ZSChildGuarderInfo/BaseDelete',
            params:{id}
        })
    }
}

export default new ZsChildGuarderInfoService();