import { SaveRefChildGardenDto } from '@/app/apiServices/zsRefChildGarden/public-api';
import { StoreOptions } from 'vuex';

const store: StoreOptions<any> = {
    state() {
        return {
            // regChildGarden: {
            //     childId: "",
            //     gardenIds: [],
            //     enterGardenType: 0,
            //     gradeCode: "",
            //     orderTime: "",
            // }
            regChildGarden: new SaveRefChildGardenDto()
        }
    },
    mutations: {
        SET_RegChildGarden(state, data) {
            state.regChildGarden = data;
            localStorage.setItem('regChildGarden', JSON.stringify(data))
        }
    },
    actions: {
        ACTION_RegChildGarden(context, data) {
            context.commit('SET_RegChildGarden', data)
        }
    },
    getters: {
        GET_RegChildGarden(state) {
            const regChildGarden = localStorage.getItem('regChildGarden');
            if (regChildGarden) {
                return JSON.parse(regChildGarden);
            }
            return state.regChildGarden;
        }
    }
};

export default store;