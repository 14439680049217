import {StoreOptions} from 'vuex';

const store : StoreOptions<any> = {
    state(){
        return{
            zsDateRange:{}
        }
    },
    mutations: {
        SET_ZsDateRange(state,data){
            state.zsDateRange = data;
            localStorage.setItem('zsDateRange',JSON.stringify(data));
        }
    },
    actions: {
        ACTION_ZsDateRange(context,data){
            context.commit('SET_ZsDateRange',data)
        }
    },
    getters:{
        GET_ZsDateRange(state){
            const zsDateRange = localStorage.getItem('zsDateRange');
            if(zsDateRange){
                return JSON.parse(zsDateRange);
            }
            return state.zsDateRange;
        }
    }
};

export default store;