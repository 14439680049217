import Vue from 'vue'

function isNullOrEmpty(val: string) {
    return !val || val == '' || val == null
}

function getStepsIcon(index: number, activeSteps: number) {
    if (index < activeSteps) {
        return "el-icon-circle-check";
    }
    else if (index == activeSteps) {
        return "el-icon-edit-outline";
    }
    else {
        return "el-icon-info";
    }
}

//设置对象
function setObject(obj: any, key: any, value: any) {
    if (obj) {
        Vue.set(obj, key, value);
    }
}

function filter(obj: Array<any>, val: string) {
    const find = obj.find(u => u.value == val);
    return find ? find.name : '';
}

function filterSelect(obj: Array<any>, val: any) {
    const find = obj.find(u => u.optionValue == val);
    return find ? find.optionText : '';
}

//根据指定字符切割字符串转html展示
function splitStrToHtml(obj: string, s?: string) {
    const splitChar = s ? s : ',';
    const arr = obj.split(splitChar);
    let result = "";
    arr.forEach((u: any, index: number) => {
        if (index != arr.length - 1) {
            result += u + ",<br>";
        }
        else {
            result += u;
        }
    });
    return result;
}

export {
    isNullOrEmpty,
    getStepsIcon,
    setObject,
    filter,
    filterSelect
}

export default {
    isNullOrEmpty,
    getStepsIcon,
    setObject,
    filter,
    filterSelect,
    splitStrToHtml
}