import { CurrentUserDto, RegistrationInfoDto, UploadEvidenceChildDto } from "@/app/apiServices/baseDto/storeDto";
import { SaveParentHoldPageDto } from "@/app/apiServices/parentHoldRegister/public-api";
import { ChildGuarderInfo } from "@/app/apiServices/zsChildGuarderInfo/public-api";
import { SaveRefChildGardenDto } from "@/app/apiServices/zsRefChildGarden/public-api";

function GET_CurrentUser() {
    console.log(process.env.VUE_APP_ENV);
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
        console.log(process.env.VUE_APP_ENV);
        
        return JSON.parse(currentUser);
    }
    return new CurrentUserDto();
}

function GET_ActiveChildId() {
    const activeChildId = localStorage.getItem('activeChildId');
    return activeChildId ? activeChildId : 0;
}

function DELETE_ActiveChildId() {
    localStorage.removeItem('activeChildId');
}

function GET_RegistrationInfo() {
    const registrationInfo = localStorage.getItem('registrationInfo');
    if (registrationInfo) {
        return JSON.parse(registrationInfo);
    }
    return new RegistrationInfoDto();
}

function GET_ParentInfomation() {
    const parentInfomation = localStorage.getItem('parentInfomation');
    if (parentInfomation) {
        return JSON.parse(parentInfomation);
    }
    return new SaveParentHoldPageDto();
}

function GET_FamilyMember() {
    const parentInfomation = localStorage.getItem('familyMember');
    if (parentInfomation) {
        return JSON.parse(parentInfomation);
    }
    return new ChildGuarderInfo();
}

function DELETE_FamilyMember() {
    localStorage.removeItem('familyMember');
}

function GET_GuarderList() {
    const parentInfomation = localStorage.getItem('guarderList');
    if (parentInfomation) {
        return JSON.parse(parentInfomation);
    }
    return [];
}

function GET_RegChildGarden() {
    const regChildGarden = localStorage.getItem('regChildGarden');
    if (regChildGarden) {
        return JSON.parse(regChildGarden);
    }
    return new SaveRefChildGardenDto();
}

function GET_UploadEvidenceChild() {
    const uploadEvidenceChild = localStorage.getItem('uploadEvidenceChild');
    if (uploadEvidenceChild) {
        return JSON.parse(uploadEvidenceChild);
    }
    return new UploadEvidenceChildDto();
}

export default {
    GET_CurrentUser,
    GET_ActiveChildId,
    GET_RegistrationInfo,
    DELETE_ActiveChildId,
    GET_ParentInfomation,
    GET_FamilyMember,
    DELETE_FamilyMember,
    GET_GuarderList,
    GET_RegChildGarden,
    GET_UploadEvidenceChild
}