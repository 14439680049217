import moment from "moment";
moment.locale('zh-cn');


export function MomentFilter(val:Date,format = "YYYY-MM-DD"){
    if(val === null || val === undefined){
        return "";
    }
    return moment(val).format(format);
}
