import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '../layout/index.vue'
Vue.use(VueRouter)

//本市
import thisCityRouter from '../pages/thisCity/router';
//非本市
import notThisCityRouter from '../pages/notThisCity/router';

// sso
import suiShenBanSSORouter from '../suiShenBanSSO/suiShenBanSSORouter'
import { BaseUrl } from '@/config';

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("../pages/home/index.vue")
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/record",
        component: () => import("../pages/record/index.vue")
      },
      {
        path: "/entrance",
        component: () => import("../pages/entrance/index.vue")
      },
      {
        path: "/uploadEvidence",
        component: () => import("../pages/uploadEvidence/index.vue")
      },
      {
        path: "/parentScore",
        component: () => import("../pages/parentScore/index.vue")
      },
    ]
  },
  thisCityRouter,
  notThisCityRouter,
  ...suiShenBanSSORouter,
]

const router = new VueRouter({
  mode: 'history',
  base: BaseUrl,
  routes
})

export default router
