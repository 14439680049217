import { RouteConfig } from 'vue-router';

const routes: RouteConfig = {
    path: '/thisCity',
    name: 'thisCity',
    // component: Layout,
    component: () => import('./index.vue'),
    children:[
        {
            path: 'childInformation',
            name: 'ChildInformation',
            component: () => import('./01-childInformation/index.vue'),
        },
        {
            path: 'familyMemberInformation',
            name: 'FamilyMemberInformation',
            component: () => import('./02-familyMemberInformation/index.vue'),
        },
        {
            path: 'familyMemberInformation/addFamilyMembers',
            name: 'AddFamilyMemberInformation',
            component: () => import('./02-familyMemberInformation/pages/actionFamilyMembers/index.vue'),
        },
        {
            path: 'familyMemberInformation/updateFamilyMembers',
            name: 'UpdateFamilyMembers',
            component: () => import('./02-familyMemberInformation/pages/actionFamilyMembers/index.vue'),
        },
        {
            path: 'materialInformation',
            name: 'MaterialInformation',
            component: () => import('./03-materialInformation/index.vue'),
        },
        {
            path: 'regChildGarden',
            name: 'RegChildGarden',
            component: () => import('./04-regChildGarden/index.vue'),
        },
        {
            path: 'confirmInformation',
            name: 'ConfirmInformation',
            component: () => import('./05-confirmInformation/index.vue'),
        },
        // {
        //     path: 'seeInformation/:childId',
        //     name: 'xiaoBanSignUpSeeInformation',
        //     component: () => import('./07-seeInformation/index.vue'),
        // },
    ]
};

export default routes;