import Vue from 'vue'
import * as components from './components/index';
import vueFilters from './filters';

export default {
    install(app:typeof Vue) {
        const HUPUUIComponents: any = components;
        for(const key in HUPUUIComponents) {
            HUPUUIComponents[key].install(app);
        }
        app.use(vueFilters);
    }
}

export * from './components/index';