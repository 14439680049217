import { UploadEvidenceChildDto } from '@/app/apiServices/baseDto/storeDto';
import { StoreOptions } from 'vuex';

const store: StoreOptions<any> = {
    state() {
        return {
            activeChildId: 0,
            uploadEvidenceChild: new UploadEvidenceChildDto(),    //待上传佐证的幼儿
        }
    },
    mutations: {
        SET_ActiveChildId(state, data) {
            state.activeChildId = data;
            localStorage.setItem('activeChildId', data);
        },
        SET_UploadEvidenceChild(state, data) {
            state.uploadEvidenceChild = data;
            localStorage.setItem('uploadEvidenceChild', JSON.stringify(data));
        }
    },
    actions: {
        ACTION_ActiveChildId(context, data) {
            context.commit('SET_ActiveChildId', data)
        },
        ACTION_UploadEvidenceChild(context, data) {
            context.commit('SET_UploadEvidenceChild', data)
        }
    },
    getters: {
        GET_ActiveChildId(state) {
            const activeChildId = localStorage.getItem('activeChildId');
            return activeChildId ? activeChildId : state.activeChildId;
        },
        GET_UploadEvidenceChild(state) {
            const uploadEvidenceChild = localStorage.getItem('uploadEvidenceChild');
            if (uploadEvidenceChild) {
                return JSON.parse(uploadEvidenceChild);
            }
            return state.uploadEvidenceChild;
        }
    }
};

export default store;