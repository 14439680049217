export class ChildAttachmentGroupDtos {
    childAttachmentGroupDtos: Array<AttachmentPage> = [];
}

export class AttachmentPage {
    fileType = 0;
    fileTypeRemark = "";
    fileTypeName = "";
    hasFile = false;
    mustSupport = false;
    isCheck = false;   //用于页面“无法提供”
    childAttachmentDtos: Array<IChildAttachment> = [];
}

export interface IChildAttachment {
    id: number;
    childId: number;
    displayName: string;
    fileName: string;
    filePath: string;
    fileSource: number | null;
    phyfilePath: string;
    fileExtName: string;
    fileType: number;
    fileTypeName: string;
    createBy: string;
    createTime: Date | null;
    updateBy: string;
    updateTime: Date | null;
    isDelete: boolean;
}

export class ChildAttachment implements IChildAttachment {
    id = 0;
    childId = 0;
    displayName = "";
    fileName = "";
    filePath = "";
    fileSource = 1;
    phyfilePath = "";
    fileExtName = "";
    fileType = 0;
    fileTypeName = "";
    createBy = "";
    createTime = null;
    updateBy = "";
    updateTime = null;
    isDelete = false;
}

export class SaveAttachmentPageDto {
    childId = 0;
    gradeCode = '';
    childAttachmentDtos: Array<IChildAttachment> = [];
    delIds: Array<number> = [];
    isSupply=false;
}

export class getAttachmentPageInput {
    childId = "";
    enterType = null;
    gradeCode = "";
    showReward = false;
}

//用于详情页展示
export class ChildDetailAttachmentDto {
    fileType = 0;
    fileTypeRemark = "";
    fileTypeName = "";
    hasFile = false;
    mustSupport = false;  //是否必须提供
    childAttachmentDtos: Array<any> = [];
}