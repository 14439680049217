export interface ITargetItem {
    itemId: number;
    name: string;
    score: number;
    isRadio: boolean;
    isChecked: boolean;
}

interface ITargetB {
    id: number;
    name: string;
    targetItems: Array<ITargetItem> | [];
    isRadio: boolean;
    isChecked: boolean;
    isDisabled: boolean;
    checkedTargetItemId: number;
    checkedTargetItemScore: number;
}

export interface ITargetA {
    name: string;
    targetBs: Array<ITargetB> | [];
}

export interface IPointTableDto {
    name: string,
    targetAs: Array<ITargetA> | [];
}

//获取打分表
export interface IGetZSPointTableGradePage {
    childId: number;
    pointTableDtos: Array<IPointTableDto> | [];
    parentScore: number;
}

interface ITarget {
    id: number;
    itemId: number;
}

export class Target implements ITarget {
    id = 0;
    itemId = 0;
}

//保存打分表
export interface ISaveZSPointTableGradePage {
    childId: number;
    parentTargets: Array<ITarget> | [];
    parentScore: number;
}

export class SaveZSPointTableGradePage implements ISaveZSPointTableGradePage {
    childId = 0;
    gradeCode = '';
    parentTargets: ITarget[] = [];
    parentScore = 0;
    isSupply = false;
}

//用于打分页面展示
export class PointTableGradeRadioDto {
    id = 0;
    lable = "";
    checkedTargetItemId = 0;
    checkedTargetItemScore = 0;
    isChecked = false;
    isRadio = false;
    isDisabled = false;
    children: PointTableGradeRadioDto[] = []
}

//用于详情页展示
export class PointTableGardeDto {
    parentScore = 0;
    targetOutputs: Array<TargetOutputDto> = [];
}

export class TargetOutputDto {
    itemId = 0;
    itemName = '';
    score = 0;
    targetA = '';
    targetB = '';
    targetId = 0;
    targetItemId = 0;
    targetSequence = '';
    tatgetItemName = '';
}

export interface IPointTableDto {
    projectName: string;
    targetAName: string;
    targetBId: number;
    targetBName: string;
    targetBIsChecked: boolean;
    targetBIsRadio: boolean;
    targetBIisDisabled: boolean;
    checkedTargetItemScore: number;
    checkedTargetItemId: number;
    targetItems: Array<ITargetItem>

    [key: string]: any
}
export class PointTableDto {
    projectName = "";
    targetAName = "";
    targetBId = 0;
    targetBName = "";
    targetBIsChecked = false;
    targetBIsRadio = false;
    targetBIsDisabled = false;
    checkedTargetItemScore = 0;
    checkedTargetItemId = 0;
    targetItems: Array<ITargetItem> = [];
}