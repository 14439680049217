import axios, { Method, AxiosInstance } from 'axios';
import { ApiUrl, ApiServicesUrl, LoginUrl } from '../../../config';
import { Toast } from 'vant';
import 'vant/lib/toast/style';

// 超时时间
axios.defaults.timeout = 20000;
axios.defaults.withCredentials = true;
export interface HttpServiceConfig {
    url: string;
    data?: any;
    params?: any;
    method?: Method;
}

export class AxiosHttpService {
    $http: AxiosInstance = axios.create({
        baseURL: ApiServicesUrl
    });

    getAxiosByApiUrl() {
        return axios.create({
            baseURL: ApiUrl
        });
    }

    httpService<T>(config: HttpServiceConfig) {
        const { $http } = this;
        if (config.method === undefined) {
            config.method = "POST";
        }
        return new Promise<T>(function (resolve) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner',
                duration: 0
            });

            $http(config).then((res: any) => {
                Toast.clear();
                resolve(res.data.result);
            }).catch((error: any) => {
                if (error.response.status == 401) {
                    Toast.clear();
                    Toast({ message: "登录过期，请重新登录", duration: 3000 });
                    //清除用户信息
                    sessionStorage.removeItem('currentUser')
                    window.location.href = LoginUrl;
                }
                else {
                    Toast.clear();
                    Toast.fail(`网络异常，请稍后再试.`);
                }
            })
        })
    }


    post<T>(config: HttpServiceConfig) {
        config.method = "POST";
        return this.httpService<T>(config);
    }

    get<T>(config: HttpServiceConfig) {
        config.method = "GET";
        return this.httpService<T>(config);
    }

    httpServiceNoCatch<T>(config: HttpServiceConfig) {
        const { $http } = this;
        if (config.method === undefined) {
            config.method = "POST";
        }
        return new Promise<T>(function (resolve, reject) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner',
                duration: 0
            });
            $http(config).then((res: any) => {
                Toast.clear();
                resolve(res.data.result);
            })
                .catch((err: any) => {
                    Toast.clear();
                    reject(err.response.data);
                });
        })
    }


    axios(config: HttpServiceConfig) {
        return axios({ ...config });
    }
}


/// 每次重新返回一个新的AxiosHttpService
export function GetNewAxiosHttpService() {
    return new AxiosHttpService();
}

export default new AxiosHttpService();

