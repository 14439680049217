import { ChildGuarderInfo } from '@/app/apiServices/zsChildGuarderInfo/public-api';
import { StoreOptions } from 'vuex';

const store: StoreOptions<any> = {
    state() {
        return {
            familyMember: new ChildGuarderInfo(),
            guarderList: [],
        }
    },
    mutations: {
        SET_FamilyMember(state, data) {
            state.familyMember = data;
            localStorage.setItem('familyMember', JSON.stringify(data))
        },
        SET_GuarderList(state, data) {
            state.guarderList = data;
            localStorage.setItem('guarderList', JSON.stringify(data))
        }
    },
    actions: {
        ACTION_FamilyMember(context, data) {
            context.commit('SET_FamilyMember', data)
        },
        ACTION_GuarderList(context, data) {
            context.commit('SET_GuarderList', data)
        }
    },
    getters: {
        GET_FamilyMember(state) {
            const familyMember = localStorage.getItem('familyMember');
            if (familyMember) {
                return JSON.parse(familyMember);
            }
            return state.familyMember;
        },
        GET_GuarderList(state) {
            const familyMember = localStorage.getItem('guarderList');
            if (familyMember) {
                return JSON.parse(familyMember);
            }
            return state.familyMember;
        }
    }
};

export default store;