import { ISelectDto } from '@/app/apiServices/baseDto/selectDto';
import { IDictionary } from '@/app/apiServices/dictionary/public-api';
import { IGarden } from '@/app/apiServices/garden/public-api';
import { IdCardToBirthday, IdCardToSex } from '@/core/utils/IdCard/public-api';
import { IVerifyResult } from '../../baseDto/resultDto';

export class SaveChildBaseInfoResult {
    data = 0;
    verifyResult: IVerifyResult = {
        message: '',
        success: false,
        exceptionCode: ''
    }
}

export interface ISaveChildBaseInfo {
    id: number;
    type: number;
    gradeCode: string;
    gB_ZJLX: string;
    gB_ZJLX_Code: string;
    gB_SFZ: string;
    isTwins: boolean | null;
    childName: string;
    nameInPinyin: string;
    oldOrEnglishName: string;
    sex: string;
    birthday: Date | null;
    health: string;
    healthCode: string;
    permanentProvince: string;
    permanentProvinceCode: string;
    permanentCity: string;
    permanentCityCode: string;
    permanentDistrict: string;
    permanentDistrictCode: string;
    permanentStreet: string;
    permanentStreetCode: string;
    juWei: string;
    juWeiCode: string;
    address: string;
    relationOfDomicile: string;
    homeProvince: string;
    homeCity: string;
    homeDistrict: string;
    homeDistrictCode: string;
    homeStreet: string;
    homeStreetCode: string;
    nowJuWei: string;
    nowJuWeiCode: string;
    postalCode: string;
    nowAddress: string;
    houseStreet: string;
    houseStreetCode: string;
    houseJuWei: string;
    houseJuWeiCode: string;
    houseAddress: string;
    houseCertCode: string;
    houseOwnerIdCard: string;
    relationOfHouseMaster: string;
    relationOfHouseMasterCode: string;
    earlyRecords: string;
    prevent: string;
    jzZ_ProcessingTime: Date | null;
    jzZ_RegisterPlace: string;
    jzZ_TermOfValidity: Date | null;
    jzZ_PermitCode: string;
    residenceStatus: string;
    residenceStatusCode: string;
    isDelete: number;
    guarderName: string;
    guarderPhone: string;
    guarderZJLX: string;
    guarderZJLX_Code: string;
    guarderZJH: string;
    guarderRelation: string;
    guarderRelation_Code: string;
    householdsTime: string;
    regFlag: number;
    lastOpAccount: string;
    lastOpTime: Date | null;
    estateTime: string;
    relationOfDomicileFC: string;
    regNumber: string;
    residencePermitType: number;
    householdImmigrationDate: Date | null;
    overseas: string;
    registerType: number;
    gB_GB: string,
    gB_GB_Code: string,
    gB_MZ: string;
    gB_MZ_Code: string;
    nativeType: string;
    nativeTypeCode: string;
    // isOnlyChild: string;
    isHQ: string;
    enterGardenType: number;
    hasReportRecord: boolean;
    hasGBReportRecord: boolean;
    verifyResult: IVerifyResult | null;
    cbReasonCode: string;
    // healthStatus: string;
    // healthRemark: string;
    //打分表是否满分
    isFullScore: boolean | null;
    // //何时由何地牵来本址
    // immigrationInfo: string;
}

export class SaveChildBaseInfo implements ISaveChildBaseInfo {
    id = 0;
    type = 0;
    gradeCode = "";
    gB_ZJLX = '身份证';
    gB_ZJLX_Code = 'ZJLX_sfz';
    gB_SFZ = '';
    isTwins = null;
    childName = '';
    nameInPinyin = '';
    oldOrEnglishName = '';
    sex = '';
    birthday: Date | null = null;
    health = '';
    healthCode = '';
    permanentProvince = '';
    permanentProvinceCode = '';
    permanentCity = '';
    permanentCityCode = '';
    permanentDistrict = '';
    permanentDistrictCode = '';
    permanentStreet = '';
    permanentStreetCode = '';
    juWei = '';
    juWeiCode = '';
    address = '';


    relationOfDomicile = '';
    homeProvince = '';
    homeCity = '';
    homeDistrict = '';
    homeDistrictCode = '';
    homeStreet = '';
    homeStreetCode = '';
    nowJuWei = '';
    nowJuWeiCode = '';
    postalCode = '';
    nowAddress = '';

    houseCounty = '';
    houseCountyCode = '';
    houseStreet = '';
    houseStreetCode = '';
    houseJuWei = '';
    houseJuWeiCode = '';
    houseAddress = '';
    houseCertCode = '';
    houseOwnerIdCard = '';

    relationOfHouseMaster = '';
    relationOfHouseMasterCode = '';
    earlyRecords = '否';
    prevent = '';
    jzZ_ProcessingTime = new Date;
    jzZ_RegisterPlace = '';
    jzZ_TermOfValidity = new Date;
    jzZ_PermitCode = '';
    residenceStatus = '';
    residenceStatusCode = '';
    isDelete = 0;
    guarderName = '';
    guarderPhone = '';
    guarderZJLX = '';
    guarderZJLX_Code = '';
    guarderZJH = '';
    guarderRelation = '';
    guarderRelation_Code = '';
    householdsTime = '';
    regFlag = 0;
    lastOpAccount = '';
    lastOpTime = null;
    estateTime = '';
    relationOfDomicileFC = '';
    regNumber = '';
    residencePermitType = 0;
    residencePermitTypeDec = "";
    householdImmigrationDate: Date | null = null;
    overseas = '';
    registerType = 0;
    gB_GB = "";
    gB_GB_Code = "";
    gB_MZ = '';
    gB_MZ_Code = '';
    nativeType = '';
    nativeTypeCode = '';
    // isOnlyChild = '否';
    isHQ = '';
    // 入园方式（1：户籍 2：房产 0：没有入园方式）
    enterGardenType = 0;
    // 是否有报名记录
    hasReportRecord = false;
    //是否有公办记录
    hasGBReportRecord = false;
    //插班原因
    cbReason = "";
    cbReasonCode = 'hhjszdry';
    // //是否健康（是、其他）    
    // healthStatus = "";
    // //健康选择其他说明
    // healthRemark = "";
    //打分表是否满分
    isFullScore = false;
    //何时由何地牵来本址
    // immigrationInfo = '';
    // 验证消息
    verifyResult = {
        message: '',
        success: true,
        exceptionCode: ''
    };


    // 根据身份证号码设置 出生日期
    setInfoByGB_SFZ() {
        this.birthday = new Date(IdCardToBirthday(this.gB_SFZ));
        this.sex = IdCardToSex(this.gB_SFZ);
    }

    // 根据身份证号码设置 性别
    setSexByGB_SFZ() {
        this.sex = IdCardToSex(this.gB_SFZ);
    }

    setPermanentStreetCode(garden: IGarden) {
        // 选择公办园报名，入园方式（户籍所在地入园、房产所在地入园）选择后，幼儿户籍地所在街道自动跟幼儿园街道匹配一致
        if (garden.enrollScope !== '全区') {
            this.permanentStreetCode = garden.streetCode;
        }
    }

    // 户籍报名  1
    // 房产报名  2
    // 示范园默认户籍报名
    // 非本市默认 0
    setEnterGardenType(typeId: number, garden: IGarden) {
        // 非本市户籍幼儿
        if (typeId === 2) {
            this.enterGardenType = 0;
        } else if (garden.enrollScope === '全区') {
            this.enterGardenType = 1;
        }
    }
}

export class ZSChildBaseInfoPageSaveDto {
    id = 0;
    type = 0;
    gB_ZJLX = '';
    gB_ZJLX_Code = '';
    gB_SFZ = '';
    childName = '';
    nameInPinyin = '';
    oldOrEnglishName = '';
    sex = '';
    birthday = new Date();
    health = '';
    healthCode = '';
    permanentProvince = '';
    permanentProvinceCode = '';
    permanentCity = '';
    permanentCityCode = '';
    permanentDistrict = '';
    permanentDistrictCode = '';
    permanentStreet = '';
    permanentStreetCode = '';
    juWei = '';
    juWeiCode = '';
    address = '';
    relationOfDomicile = '';
    homeProvince = '';
    homeCity = '';
    homeDistrict = '';
    homeDistrictCode = '';
    homeStreet = '';
    homeStreetCode = '';
    nowJuWei = '';
    nowJuWeiCode = '';
    postalCode = '';
    nowAddress = '';
    houseStreet = '';
    houseStreetCode = '';
    houseJuWei = '';
    houseJuWeiCode = '';
    houseAddress = '';
    relationOfHouseMaster = '';
    relationOfHouseMasterCode = '';
    earlyRecords = '';
    prevent = '';
    jzZ_ProcessingTime = new Date();
    jzZ_RegisterPlace = '';
    jzZ_TermOfValidity = new Date();
    jzZ_PermitCode = '';
    residenceStatus = '';
    residenceStatusCode = '';
    isDelete = 0;
    guarderName = '';
    guarderPhone = '';
    guarderZJLX = '';
    guarderZJLX_Code = '';
    guarderZJH = '';
    guarderRelation = '';
    guarderRelation_Code = '';
    householdsTime = '';
    regFlag = 0;
    lastOpAccount = '';
    lastOpTime = new Date();
    estateTime = '';
    relationOfDomicileFC = '';
    regNumber = '';
    residencePermitType = 0;
    householdImmigrationDate = new Date();
    overseas = '';
    registerType = 0;
    gB_GB = '';
    gB_GB_Code = '';
    gB_MZ = '';
    gB_MZ_Code = '';
    nativeType = '';
    nativeTypeCode = '';
    // isOnlyChild = '';
    isHQ = '';
    enterGardenType = 0;
    gardenId = 0;
}

export interface ZSChildBaseInfoPageInput {
    childName: string;
    birthday: Date;
    cardNumber: string;
    cardType: string;
    gradeCode: string;
    childType: string;
}

export interface getZSChildBaseInfoPageDto extends ISaveChildBaseInfo {
    holdCardSelect: Array<ISelectDto> | [];
    residenceSelect: Array<ISelectDto> | [];
    cqrSelectSelect: Array<ISelectDto> | [];
    countrySelect: Array<ISelectDto> | [];
    shengshiquSelect: Array<IDictionary> | [];

    cbReasonSelect: Array<ISelectDto> | [];
    enterTypeSelect: Array<ISelectDto> | [];
    estateTimeSelect: Array<ISelectDto> | [];
    nativeTypeSelect: Array<ISelectDto> | [];
    relationOfDomicileSelect: Array<ISelectDto> | [];
}

export class ChildBaseInfo {
    id = 0;
    type = 0;
    gB_ZJLX = '身份证';
    gB_ZJLX_Code = 'ZJLX_sfz';
    gB_SFZ = '';
    childName = '';
    nameInPinyin = '';
    oldOrEnglishName = '';
    sex = '';
    birthday: Date | null = null;
    health = '';
    healthCode = '';
    //户籍信息
    permanentProvince = '';
    permanentProvinceCode = '';
    permanentCity = '';
    permanentCityCode = '';
    permanentDistrict = '';
    permanentDistrictCode = '';
    permanentStreet = '';
    permanentStreetCode = '';
    juWei = '';
    juWeiCode = '';
    address = '';
    relationOfDomicile = '';
    //居住地信息
    homeProvince = '';
    homeCity = '';
    homeDistrict = '';
    homeDistrictCode = '';
    homeStreet = '';
    homeStreetCode = '';
    nowJuWei = '';
    nowJuWeiCode = '';
    postalCode = '';
    nowAddress = '';
    //房产信息
    houseStreet = '';
    houseStreetCode = '';
    houseJuWei = '';
    houseJuWeiCode = '';
    houseAddress = '';
    houseCertCode = '';
    houseOwnerIdCard = '';
    relationOfHouseMaster = '';
    relationOfHouseMasterCode = '';

    earlyRecords = '否';
    prevent = '';
    jzZ_ProcessingTime = new Date;
    jzZ_RegisterPlace = '';
    jzZ_TermOfValidity = new Date;
    jzZ_PermitCode = '';
    residenceStatus = '';
    residenceStatusCode = '';
    isDelete = 0;
    guarderName = '';
    guarderPhone = '';
    guarderZJLX = '';
    guarderZJLX_Code = '';
    guarderZJH = '';
    guarderRelation = '';
    guarderRelation_Code = '';
    householdsTime = '';
    regFlag = 0;
    lastOpAccount = '';
    lastOpTime = null;
    estateTime = '';
    relationOfDomicileFC = '';
    regNumber = '';
    residencePermitType = 0;
    householdImmigrationDate: Date | null = null;
    overseas = '';
    healthStatus = '';
    healthRemark = '';
    immigrationInfo = '';
    regAccount = '';
}