import Vue from 'vue'
import App from './App.vue'
import router from './app/router'
import store from './app/store'

Vue.config.productionTip = false;

import HUPUUI from "./core/HUPU-UI";
Vue.use(HUPUUI);

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// import ViewUI from 'view-design';
// import './app/assets/iviewui.less';
// Vue.use(ViewUI);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import date from './core/utils/date'
Vue.prototype.$date = date;

import utils from './core/utils/index'
Vue.prototype.$utils = utils;

import storage from './core/utils/storage'
Vue.prototype.$storage = storage;
// //打印
// // @ts-ignore
// import Print from 'vue-print-nb'; 
// Vue.use(Print);


// //导出pdf
// // @ts-ignore
// import htmlToPdf from '@/core/utils/htmlToPdf.js'
// Vue.use(htmlToPdf)

import messageConfig from './config/message'
Vue.prototype.$messageConfig = messageConfig;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
