export function IdCardToBirthday(idCard: string) {
    // let birthday = new Date().toLocaleDateString();
    let birthday = getLocaleDateString();
    if (idCard != null && idCard != "") {
        if (idCard.length == 15) {
            birthday = "19" + idCard.substr(6, 6);
        } else if (idCard.length == 18) {
            birthday = idCard.substr(6, 8);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    }
    return birthday;
}

//获取当前时间  yyyy/MM/dd
function getLocaleDateString() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const nowDate = year + (month < 10 ? "0" + month : month.toString()) + (day < 10 ? "0" + day : day.toString());
    return nowDate;
}