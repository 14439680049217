///////////////////////////////////////   小班   ///////////////////////////////////////
//本市报名园所限制
export const xbThisCityGardenMessage = "无论是否报名市级示范园，本市户籍幼儿都必须在户籍（房产）所在街道中的非示范性公办幼儿园中至少选择一所报名。";
export const xbThisCityGardenMessage2 = "同一个示范园只能选择一个园部，如：荷花池幼儿园和荷花池幼儿园(海珀部) 只能二者选一，思南路幼儿园和思南路幼儿园(南部) 只能二者选一，蓬莱路幼儿园和蓬莱路幼儿园(中福部) 只能二者选一，请家长根据实际情况就近报名，原则上一经报名不得修改。";
//户籍报名
export const xbThisCityGardenToHJMessage = "根据招生政策，您在报名市示范性幼儿园的同时，必须选择至少一所户籍所在街道的其他幼儿园进行报名";
//房产报名
export const xbThisCityGardenToFCMessage = "根据招生政策，您在报名市示范性幼儿园的同时，必须选择至少一所房产所在街道的其他幼儿园进行报名";
//非本市报名园所提示
export const xbNotThisCityGardenMessage = "非本市户籍幼儿可在居住证所在街道内选择一所公办幼儿园,同时可就近选择一所民办托幼园作为报名点,报名点仅作为验证点,与最后录取无直接关联,公办及民办托幼园所都将依证打分逐步安排。";
//非本市报名提交验证
export const xbNotThisCitySubmitMessage = "非本市小班最多选择一所公办+一所民办";
//完成报名提示
export const finishMessage = "您已完成了网上填报，所有网上报名信息必须经验证后方可生效，请等待园所审核验证。您可在报名记录页面查询报名状态。";


///////////////////////////////////////   中、大班   ///////////////////////////////////////

//完成报名提示
export const zdFinishMessage = "您已完成了网上填报。";


///////////////////////////////////////   托班   ///////////////////////////////////////
//本市报名园所限制
export const tbGardenMessage = "每位幼儿只能选择一所公办（民办）托班进行入托需求登记。";
//接受统筹提示
export const tbAdjustMessage = "愿意接受在全区范围统筹安排";
//托班无公办园所提示
export const tbNotGBMessage = "您填报的登记信息暂不符合本街道公办幼儿园托班登记条件。";
//完成报名提示
export const tbFinishMessage = "您已完成了网上填报，实际录取条件与方式由相关托幼机构制定，能否录取以登记人数和托幼机构实际托额情况而定。";


///////////////////////////////////////   公共的   ///////////////////////////////////////
//积分通知书提示
export const standardScoreMessage = "需上传积分通知书原件照片，积分通知书由区人力资源和社会保障局出具，积分通知书上显示的居住地在招生区域内，且注明登记儿童本人为同住子女！"
//系统未开放
export const noOpen = "黄浦区适龄幼儿入园报名系统尚未开放。";
//系统开放，但不允许报名
export const noRegister = "黄浦区适龄幼儿入园报名开放时间为：2023年5月8日-5月16日（每天8:00 - 23:00）";
//报名结束
export const registerEnd = "黄浦区适龄幼儿入园报名已结束。";



//系统名称
export const systemTitle = "黄浦区适龄幼儿入园报名系统";
//重新报名
export const reentryMessage = "该幼儿已经完成报名，如果继续，将删除该幼儿之前报名园所的登记信息，以本次登记信息为准，点击「确认」将继续报名。"
//已报名验证
export const hasRegister = "您的孩子已经完成了报名，如果您需要修改他/她的报名信息，请返回报名记录页并选择「重新报名」操作";
//提交报名前提示
export const submitMessage = "确认当前报名信息无误后，请点击「确认」按钮提交。";
//查询电子证照
export const queryZZKMessage = "目前仅支持上海市公共管理与服务机构签发的部分证照，未加载到的证照可以拍照手动上传，无需重复加载。";
//户籍情况不符合报名要求
export const householdInconformityMessage = "您孩子的户籍情况不在此次幼儿园报名范围";
//房产情况不符合报名要求
export const houeseInconformityMessage = "您的房产情况不在此次幼儿园报名范围";


//请求错误
export const requestError = "网络请求失败！";


export default {
    systemTitle,
    requestError,
    xbThisCityGardenMessage,
    xbThisCityGardenMessage2,
    xbThisCityGardenToHJMessage,
    xbThisCityGardenToFCMessage,
    xbNotThisCityGardenMessage,
    xbNotThisCitySubmitMessage,
    tbGardenMessage,
    tbAdjustMessage,
    tbNotGBMessage
}