//当前用户
export class CurrentUserDto {
    token = '';
    encryptId = '';
    data = {}
}

//登记幼儿信息
export class RegistrationInfoDto {
    childId = 0;
    cardTypeName = '';//证件类型名称
    cardType = '';//证件类型Code
    cardNumber = '';  //证件号
    childName = '';  //姓名
    regNumber = '';   //登记编号
    birthday = '';    //生日
    childType = 0;   //户籍类型
    gradeCode = '';    //年级code
    enterGardenType = 0;    //入园方式
}

//上传佐证
export class UploadEvidenceChildDto {
    childId = 0;
    enterGardenType = 0;
    gradeCode = "";
    gardenId = 0;
    refId = 0;
}