export interface IChildGuarderInfo {
    id: number;
    childId: number;
    gradeCode: string;
    relationship: string;
    relationshipCode: string;
    name: string;
    birthday: Date;
    cardType: string;
    cardTypeCode: string;
    cardNO: string;
    education: string;
    gB_ZZMM: string;
    gB_ZZMM_Code: string;
    gB_HYZK: string;
    gB_HYZK_Code: string;
    householdPlace: string;
    gB_GB: string;
    gB_GB_Code: string;
    gB_GATQ: string;
    gB_GATQ_Code: string;
    gB_JG: string;
    gB_MZ: string;
    gB_MZ_Code: string;
    nowAddress: string;
    workUnit: string;
    technical: string;
    telephone: string;
    email: string;
    jzZ_ProcessingTime: Date;
    jzZ_RegisterPlace: string;
    jzZ_TermOfValidity: Date;
    paymentTerm: number;
    invest: string;
    gB_XXR: string;
    gB_ZY: string;
    lastOpAccount: string;
    lastOpTime: Date;
    defaultGuarder: boolean;
}

export class ChildGuarderInfo implements IChildGuarderInfo {
    id = 0;
    defaultGuarder = true;
    childId = 0;
    gradeCode = '';
    relationship = '';
    relationshipCode = '';
    name = '';
    birthday = new Date();
    cardType = '居民身份证';
    cardTypeCode = 'ZJLX_sfz';
    cardNO = '';
    education = '';
    gB_ZZMM = '';
    gB_ZZMM_Code = '';
    gB_HYZK = '';
    gB_HYZK_Code = '';
    householdPlace = '';
    gB_GB = '';
    gB_GB_Code = '';
    gB_GATQ = '';
    gB_GATQ_Code = '';
    gB_JG = '';
    gB_MZ = '';
    gB_MZ_Code = '';
    nowAddress = '';
    workUnit = '';
    technical = '';
    telephone = '';
    email = '';
    jzZ_ProcessingTime = new Date();
    jzZ_RegisterPlace = '';
    jzZ_TermOfValidity = new Date();
    paymentTerm = 0;
    invest = '';
    gB_XXR = '';
    gB_ZY = '';
    lastOpAccount = '';
    lastOpTime = new Date();
}