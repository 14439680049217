import Vue from 'vue';

import { IdCardEncryption } from './idCard';
import { MomentFilter } from './time'

export default {
    install(app: typeof Vue) {
        app.filter('idCard',IdCardEncryption)
        app.filter('time',MomentFilter)
    }
}