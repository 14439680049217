import moment from "moment";

function formatDate(val: string, format: string) {
    return moment(new Date(val)).format(format);
}

//默认转换时间格式为2023-01-01
function formatD(val: string) {
    return moment(new Date(val)).format('YYYY-MM-DD');
}

export default {
    formatDate,
    formatD
}