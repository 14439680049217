export class ChildOhterInfoDto {
    id = 0;
    baseId = 0;
    grade = '';
    gradeCode = '';
    bloodType = '';
    bloodTypeCode = '';
    gB_GB = '';
    gB_GB_Code = '';
    gB_HKXZ = '';
    gB_HKXZ_Code = '';
    gB_MZ = '';
    gB_MZ_Code = '';
    gB_CSD = '';
    nativePlace = '';
    isOnlyChild = '';
    isStayAtHomeChild = '';
    isAcceptSubsidize = '';
    isOrphan = '';
    isLodge = '';
    isDisabledChild = '';
    disabledChildType = '';
    disabledChildTypeCode = '';
    gB_GATQ = '';
    gB_GATQ_Code = '';
    martyrsChild = '';
    isMigrantChild = '';
    peasantChild = '';
    isLowIncome = '';
    familyType = '';
    familyTypeCode = '';
    nativeType = '';
    nativeTypeCode = '';
    lastOpAccount = '';
    lastOpTime = null;
}