import {StoreOptions} from 'vuex';

const store : StoreOptions<any> = {
    state(){
        return{
            childAttachments : []
        }
    },
    mutations: {
        SET_ChildAttachments(state,data){
            state.activeGarten = data;
            localStorage.setItem('childAttachments',JSON.stringify(data))
        }
    },
    actions: {
        ACTION_ChildAttachments(context,data){
            context.commit('SET_ChildAttachments',data)
        }
    },
    getters:{
        GET_ChildAttachments(state){
            const childAttachments = localStorage.getItem('childAttachments');
            if(childAttachments){
                return JSON.parse(childAttachments);
            }
            return state.childAttachments;
        }
    }
};

export default store;